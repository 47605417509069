import { createOption } from '../../../../components'
import { DropdownOption } from '../../../../components/Dropdown/Dropdown.type'
import { stageAndSectionNames } from './StageSectionNames.data'

// Returns a list of stage names formatted for use in Dropdowns and Autocompletes
export const getStageNameOptions = (): DropdownOption[] =>
  Object.entries(stageAndSectionNames).map(([slug, label]) =>
    createOption(label.name, slug)
  )

export const getStageLocalizationForSlug = (slug: string): string =>
  stageAndSectionNames[slug].name

export const getShortStageLocalizationForSlug = (slug: string): string =>
  stageAndSectionNames[slug].code

export const getSlugForStageLabel = (label: string): string | undefined => {
  const entries = Object.entries(stageAndSectionNames)
  const match = entries.find((entry) => entry[1].name === label)

  return match ? match[0] : undefined
}
